<template>
  <div class="bg-[#21323C]">
    <div class="flex items-center justify-center pt-[2rem]">
      <h2 class="!font-bold text-2xl text-white fm:text-sm">RollerCoin Reward Calculator</h2>
    </div>
    <div class="container-fluid flex mt-[8rem] gap-8">
      <div class="w-[20%]">
        <div id="pos-article-display-sticky-67351"></div>
      </div>
      <div class="w-[60%]">
        <div class="w-full">
          <div class="flex justify-center items-center flex-col gap-5">
            <div class="flex flex-col gap-2 w-[50%]">
              <div class="relative z-0 w-full mb-5 group">
                <input type="text" v-model="goalPower" id="goalPower" class="block py-2.5 px-0 w-full fm:text-sm text-gray-200 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-blue-500 peer" placeholder=" " required />
                <label for="goalPower"
                       class="peer-focus:font-medium absolute fm:text-sm text-gray-200 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Your power (PH)</label>
              </div>
            </div>
            <div class="flex flex-col gap-2 w-[50%]">
              <Button text="CALCULATE" class="w-full" my_class="!w-full !bg-[#D0A16B] hover:!bg-[#b08859] !rounded-full" :btnLoading="btnLoading" @click="!btnLoading ? getPower() : ''" />
            </div>
            
            <div class="">
              <!--          For mobile-->
              <table class="fd:hidden w-full flex flex-row flex-no-wrap sm:bg-white overflow-hidden sm:shadow-lg my-5">
                <thead class="text-white">
                <tr class="mobile-tr bg-[#21323C] flex flex-col flex-no mobile-border w-[5rem] border-l-2 border-t-2 border-b-2 wrap sm:table-row !font-bold sm:mb-0" v-for="(item,index) in Object.keys(allData)" :key="index">
                  <th class="p-3 text-left fm:text-sm">{{item}}</th>
                </tr>
                </thead>
                <tbody class="flex-1 sm:flex-none">
                <tr class="flex flex-col flex-no wrap sm:table-row mb-2 mobile-tr">
                  <td class="border-2 p-3 !font-bold text-white fm:text-sm mobile-border"  v-for="(item,index) in Object.keys(allData)" :key="index">{{allData[item]}}$</td>
                </tr>
                </tbody>
              </table>


              <!--          For desktop-->
              <div class="relative overflow-x-hidden fm:hidden">
                <table class="w-full fm:text-sm text-left rtl:text-right text-gray-500 border-2 !font-bold">
                  <thead class="fm:text-xs text-white uppercase bg-[#21323C] border-2 !font-bold">
                  <tr>
                    <th scope="col" class="px-6 py-3 border-2 !font-bold" v-for="(item,index) in Object.keys(allData)" :key="index">
                      {{item}}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr class="bg-[#21323C] border-2 !font-bold">
                    <th scope="row" class="border-2 !font-bold px-4 py-3 fm:text-sm text-white whitespace-nowrap" v-for="(item,index) in Object.keys(allData)" :key="index">
                      {{allData[item]}}$
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>

            </div>

          </div>
<!--          <div class="mt-[8rem]">-->
<!--            <div id="pos-article-text-card-69241"></div>-->
<!--            <div id="pos-article-display-card-69240"></div>-->
<!--          </div>-->
        </div>
      </div>
      <div class="w-[20%]">
        <div id="pos-article-display-67347"></div>
      </div>

    </div>
    <Loading :loading="loading" />
    <Meta type="name" title="author" description="Armia Evil & Captain Matthew" />
    <Meta type="name" title="description" description="Real-time Reward Estimates: Get instant estimates of your RollerCoin block rewards based on current network conditions." />
    <Meta type="name" title="keywords" description="rollerCoin, reward calculator, rollercoin,network strength,maximize earnings,How to calculate RollerCoin block rewards,Tips for maximizing RollerCoin earnings" />
    <Meta type="property" title="og:title" description="RollerCoin Reward Calculator" />
    <Meta type="property" title="og:url" :description="currentUrl" />
    <Meta type="property" title="og:image" :description="FavIcon" />
    <Meta type="property" title="article:published_time" description="2024-06-1" />
    <Meta type="property" title="article:modified_time" description="2024-06-1" />
    <Meta :title="$store.state.siteName + ` | RollerCoin Reward Calculator `"/>
  </div>
</template>

<script>
export default{
  name:'App'
}
</script>

<script setup>

import {computed, onMounted, ref} from "vue";
import axios from "@/plugins/axios";
import Loading from "@/components/Loading.vue";
import Meta from "@/components/Meta.vue";
import Button from "@/components/Button.vue";
import Toast from "@/plugins/toast";
import store from "@/store";
import FavIcon from "@/assets/logo.png";

let allData = ref([])
let goalPower = ref('')
let loading = ref(false)
let btnLoading = ref(false)
let currentUrl = ref(window.location.href)
onMounted(async ()=>{
  store.dispatch('setFavicon',FavIcon)
})
async function getPower(){
  if(parseInt(goalPower.value) === NaN){
    Toast.error("Please send a number")
  }else{
    btnLoading.value = true;
    await axios.get(`${store.state.api}/power?power=${goalPower.value}`).then(resp=>{
      allData.value = resp.data.data;
    }).catch(err=>{
      Toast.error("Something is wrong try again or refresh the page", 10000)
    })
    btnLoading.value = false
  }
}

</script>

<style>
.mobile-border:nth-child(odd) {
  border-bottom: 0;
}
.mobile-border:nth-child(even) {
  border-bottom: 0;
}

.mobile-border:last-of-type {
    border: 1px solid white !important;
}
</style>





